import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness'])
  },
  methods: {
    ...mapActions('kyb', ['fetchAllOwners', 'listAllBusiness', 'updateOwner']),
    ...mapMutations('kyb', ['setSelectedBusiness'])
  },
  created() {
    /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType]}*/
    const selectedBusiness = this.getSelectedBusiness;
    if (!selectedBusiness) {
      const loader = this.showLoader();
      this.listAllBusiness()
        .then((r) => {
          if (r.total !== 0) {
            /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']} */
            const businessList = r.data;
            this.setSelectedBusiness(businessList[0]);
            if (
              this.afterBusiness &&
              typeof this.afterBusiness === 'function'
            ) {
              setTimeout(() => {
                this.afterBusiness(businessList[0]);
              }, 0);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    } else {
      if (this.afterBusiness && typeof this.afterBusiness === 'function') {
        this.afterBusiness(selectedBusiness);
      }
    }
  }
};
